<template>
	<div style="height: 100%">
		<div style="padding: 20px; height: 800px">
			<!-- 搜素框 -->
			<el-form
				:inline="true"
				:model="params"
				style="
					padding-top: 20px;
					padding-left: 20px;
					margin-bottom: 20px;
					background-color: #fff;
					border-radius: 8px;
				"
				size="small"
			>
				<el-form-item label="公司编号">
					<el-input v-model="params.companyId" placeholder="请输入公司编号"></el-input>
				</el-form-item>
				<el-form-item label="公司名">
					<el-input v-model="params.companyManager" placeholder="请输入公司名"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button @click="checkData" type="primary" size="mini">查询</el-button>
					<el-button type="info" @click="reset" size="mini">重置</el-button>
				</el-form-item>
			</el-form>
			<!-- 表格 -->
			<div style="height: 82%; background-color: #fff; border-radius: 8px; padding-top: 8px">
				<div style="width: 98%; margin: auto; height: 80%">
					<el-button
						type="primary"
						size="small"
						style="float: right; margin-top: 20px; margin-bottom: 20px"
						@click="add"
						v-show="authorization('/home-admin/routineManage/company-manage/add')"
						>新增</el-button
					>
					<el-table
						:data="tableData"
						stripe
						height="100%"
						size="small"
						:header-cell-style="{
							'background-color': '#84aace4a',
							color: '#343333',
							'text-align': 'center',
						}"
						:cell-style="{ 'text-align': 'center' }"
					>
						<el-table-column prop="companyId" label="公司编号" width="300"></el-table-column>
						<el-table-column prop="companyManager" label="公司名称"></el-table-column>
						<el-table-column prop="orderIndex" label="排序" width="100"></el-table-column>
						<!-- <el-table-column prop="orderPrice" label="普通订单单笔费用"></el-table-column>
						<el-table-column prop="repairPrice" label="维修订单单笔费用"></el-table-column>
						<el-table-column prop="additionsTask" label="增项费用抽成比例"></el-table-column>
						<el-table-column
							prop="masterTaskType"
							label="师傅抽成类型"
							:formatter="typeFormat"
						></el-table-column>
						<el-table-column prop="masterTaskValue" label="师傅抽成值"></el-table-column>
						<el-table-column
							prop="salesmanTaskType"
							label="业务员抽成类型"
							:formatter="typeFormat"
						></el-table-column>
						<el-table-column prop="salesmanTaskValue" label="业务员抽成值"></el-table-column> -->
						<el-table-column
							prop="createTime"
							label="创建时间"
							:formatter="dateFormat"
							width="140px"
						></el-table-column>
						<el-table-column label="操作" fixed="right" width="110">
							<template slot-scope="scope">
								<el-tooltip
									effect="dark"
									content="编辑"
									placement="top-end"
									v-show="authorization('/home-admin/routineManage/company-manage/edit')"
								>
									<el-button
										type="primary"
										icon="el-icon-edit"
										circle
										size="mini"
										@click="edit(scope.row)"
										style="margin-right: 5px"
									></el-button>
								</el-tooltip>
								<el-tooltip
									effect="dark"
									content="删除"
									placement="top-end"
									v-show="authorization('/home-admin/routineManage/company-manage/del')"
								>
									<el-popconfirm title="确认删除吗？" @confirm="deleteUser(scope.row)">
										<el-button
											content="删除"
											slot="reference"
											type="danger"
											icon="el-icon-delete"
											circle
											size="mini"
										></el-button>
									</el-popconfirm>
								</el-tooltip>
							</template>
						</el-table-column>
					</el-table>
					<div
						style="
							padding-top: 20px;
							height: 40px;
							background-color: #fff;
							width: 100%;
							border-radius: 8px;
							text-align: right;
						"
					>
						<el-pagination
							background
							:page-sizes="sizes"
							layout="total,sizes,prev,pager,next"
							:total="total"
							@current-change="handleCurrentChange"
							@size-change="sizeChange"
						></el-pagination>
					</div>
				</div>
			</div>
		</div>
		<el-dialog
			title="新增"
			:visible.sync="dialogFormAdd"
			width="700px"
			:close-on-click-modal="false"
		>
			<el-form
				:model="companyArray"
				:rules="rules"
				ref="companyFormAdd"
				label-width="160px"
				size="small"
				style="width: 600px"
			>
				<el-form-item label="公司ID" prop="companyId">
					<el-input v-model="companyArray.companyId"></el-input>
				</el-form-item>
				<el-form-item label="公司名" prop="companyManager">
					<el-input v-model="companyArray.companyManager"></el-input>
				</el-form-item>
				<el-form-item label="下单充电桩类型" prop="storeId">
					<el-select
						filterable
						multiple
						v-model="companyArray.categoryIdList"
						placeholder="请选择拥有充电桩类型"
						style="width: 100%"
					>
						<el-option
							v-for="item in deviceCategoryList"
							:key="item.id"
							:label="item.categoryName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="排序" prop="orderIndex">
					<el-input-number v-model="companyArray.orderIndex" :min="0"></el-input-number>
				</el-form-item>
				<!-- <el-form-item label="普通订单单价" prop="orderPrice">
					<el-input type="number" v-model="companyArray.orderPrice"></el-input>
				</el-form-item>
				<el-form-item label="维修订单单价" prop="repairPrice">
					<el-input type="number" v-model="companyArray.repairPrice"></el-input>
				</el-form-item>
				<el-form-item label="增项费用抽成比例" prop="additionsTask">
					<el-input type="number" v-model="companyArray.additionsTask"></el-input>
				</el-form-item>
				<el-form-item label="师傅抽成类型" prop="masterTaskType">
					<el-select v-model="companyArray.masterTaskType" placeholder="请选择" style="width: 100%">
						<el-option label="按比例(百分比)" :value="1"></el-option>
						<el-option label="具体值" :value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="师傅抽成值" prop="masterTaskValue">
					<el-input type="number" v-model="companyArray.masterTaskValue"></el-input>
				</el-form-item>
				<el-form-item label="业务员抽成类型" prop="salesmanTaskType">
					<el-select
						v-model="companyArray.salesmanTaskType"
						placeholder="请选择"
						style="width: 100%"
					>
						<el-option label="按比例(百分比)" :value="1"></el-option>
						<el-option label="具体值" :value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="业务员抽成值" prop="salesmanTaskValue">
					<el-input type="number" v-model="companyArray.salesmanTaskValue"></el-input>
				</el-form-item> -->
			</el-form>
			<div slot="footer" style="margin-top: 50px">
				<el-button @click="dialogFormAdd = false" size="mini">取 消</el-button>
				<el-button type="primary" @click="addSubMit('companyFormAdd')" size="mini">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 编辑弹出层 -->
		<el-dialog
			title="编辑"
			:visible.sync="dialogFormEdit"
			width="700px"
			:close-on-click-modal="false"
		>
			<el-form
				:model="companyArray"
				:rules="rules"
				ref="companyFormAdd"
				label-width="160px"
				size="small"
				style="width: 600px"
			>
				<el-form-item label="公司ID" prop="companyId">
					<el-input v-model="companyArray.companyId"></el-input>
				</el-form-item>
				<el-form-item label="公司名" prop="companyManager">
					<el-input v-model="companyArray.companyManager"></el-input>
				</el-form-item>
				<el-form-item label="下单充电桩类型" prop="storeId">
					<el-select
						filterable
						multiple
						v-model="companyArray.categoryIdList"
						@change="categoryIdListChange"
						placeholder="请选择拥有充电桩类型"
						style="width: 100%"
					>
						<el-option
							v-for="item in deviceCategoryList"
							:key="item.id"
							:label="item.categoryName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="排序" prop="orderIndex">
					<el-input-number v-model="companyArray.orderIndex" :min="0"></el-input-number>
				</el-form-item>
				<!-- <el-form-item label="普通订单单价" prop="orderPrice">
					<el-input type="number" v-model="companyArray.orderPrice"></el-input>
				</el-form-item>
				<el-form-item label="维修订单单价" prop="repairPrice">
					<el-input type="number" v-model="companyArray.repairPrice"></el-input>
				</el-form-item>
				<el-form-item label="增项费用抽成比例" prop="additionsTask">
					<el-input type="number" v-model="companyArray.additionsTask"></el-input>
				</el-form-item>
				<el-form-item label="师傅抽成类型" prop="masterTaskType">
					<el-select v-model="companyArray.masterTaskType" placeholder="请选择" style="width: 100%">
						<el-option label="按比例(百分比)" :value="1"></el-option>
						<el-option label="具体值" :value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="师傅抽成值" prop="masterTaskValue">
					<el-input type="number" v-model="companyArray.masterTaskValue"></el-input>
				</el-form-item>
				<el-form-item label="业务员抽成类型" prop="salesmanTaskType">
					<el-select
						v-model="companyArray.salesmanTaskType"
						placeholder="请选择"
						style="width: 100%"
					>
						<el-option label="按比例(百分比)" :value="1"></el-option>
						<el-option label="具体值" :value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="业务员抽成值" prop="salesmanTaskValue">
					<el-input type="number" v-model="companyArray.salesmanTaskValue"></el-input>
				</el-form-item> -->
			</el-form>
			<div slot="footer" style="margin-top: 50px">
				<el-button @click="dialogFormEdit = false" size="mini">取 消</el-button>
				<el-button type="primary" @click="editSubMit('companyFormAdd')" size="mini"
					>确 定</el-button
				>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import moment from 'moment';
	import { authorization } from '../../../authorization/authorization';
	export default {
		data() {
			return {
				tableData: [],
				params: {
					companyId: '',
					companyManager: '',
					page: 1,
					size: 10,
				},
				deviceCategoryList: [],
				dialogFormAdd: false,
				dialogFormEdit: false,
				companyArray: {
					id: '',
					companyId: '',
					companyManager: '',
					additionsTask: '',
					masterTaskType: '',
					masterTaskValue: '',
					orderPrice: '',
					repairPrice: '',
					salesmanTaskType: '',
					salesmanTaskValue: '',
				},
				rules: {
					companyId: [{ required: true, message: '请输入公司ID', trigger: 'blur' }],
					companyManager: [{ required: true, message: '请输入公司名', trigger: 'blur' }],
					masterTaskType: [{ required: true, message: '请选择师傅抽成类型', trigger: 'blur' }],
					masterTaskValue: [{ required: true, message: '请输入师傅抽成值', trigger: 'blur' }],
					salesmanTaskType: [{ required: true, message: '请选择业务员抽成类型', trigger: 'blur' }],
					salesmanTaskValue: [{ required: true, message: '请输入业务员抽成值', trigger: 'blur' }],
					orderPrice: [{ required: true, message: '请输入普通订单单价', trigger: 'blur' }],
					repairPrice: [{ required: true, message: '请输入维修订单单价', trigger: 'blur' }],
				},
				total: 0,
				sizes: [10, 15],
			};
		},
		created() {
			this.queryCompanyList();
			this.queryDeviceCategoryList();
		},
		methods: {
			authorization,
			queryCompanyList() {
				//获取列表信息
				this.axios({
					method: 'get',
					url: '/v1/web/company-manager/select-company',
					params: this.params,
				}).then((res) => {
					this.tableData = res.data.data.list;
					this.total = res.data.data.total;
				});
			},
			add() {
				//新增弹出层
				this.dialogFormAdd = true;
				this.companyArray = {};
			},
			addSubMit(formName) {
				//新增提交
				this.$refs[formName].validate((valid) => {
					if (!valid) {
						return false;
					} else {
						this.axios({
							method: 'post',
							url: '/v1/web/company-manager/add-company',
							data: this.companyArray,
						}).then((res) => {
							if (res.data.code === 200) {
								this.dialogFormAdd = false;
								this.$message({
									message: '添加成功',
									type: 'success',
								});
								this.queryCompanyList();
							} else {
								this.$message.error(res.data.message);
							}
						});
					}
				});
			},
			edit(row) {
				//编辑弹出层
				this.companyArray = row;
				this.dialogFormEdit = true;
			},
			editSubMit(formName) {
				//编辑提交
				this.$refs[formName].validate((valid) => {
					if (!valid) {
						return false;
					} else {
						this.axios({
							method: 'post',
							url: '/v1/web/company-manager/update-company',
							data: this.companyArray,
						}).then((res) => {
							if (res.data.code === 200) {
								this.dialogFormEdit = false;
								this.$message({ message: '修改成功', type: 'success' });
								this.queryCompanyList();
							} else {
								this.$message.error(res.data.message);
							}
						});
					}
				});
			},
			deleteUser(row) {
				//删除
				this.axios({
					method: 'delete',
					url: '/v1/web/company-manager/delete-company/' + row.id,
					params: {
						id: row.id,
					},
				}).then((res) => {
					if (res.data.code === 200) {
						this.queryCompanyList();
					} else {
						this.$message({ type: 'error', message: res.data.message });
					}
				});
			},
			typeFormat: function (row) {
				if (row.masterTaskType == 1 || row.salesmanTaskType == 1) {
					return '比例';
				} else if (row.masterTaskType == 2 || row.salesmanTaskType == 2) {
					return '具体值';
				}
			},
			dateFormat: function (row) {
				if (row.createTime !== null && row.createTime !== undefined) {
					return moment(row.createTime).format('YYYY-MM-DD HH:mm:ss');
				}
			},
			handleCurrentChange(val) {
				this.params.page = val;
				this.queryCompanyList();
			},
			sizeChange(val) {
				this.params.size = val;
				this.params.page = 1;
				this.queryCompanyList();
			},
			checkData() {
				this.queryCompanyList();
			},
			reset() {
				this.params = { page: 1, size: 10 };
				this.queryCompanyList();
			},
			queryDeviceCategoryList() {
				this.axios({
					method: 'get',
					url: '/v1/web/device-category/select-category',
				}).then((res) => {
					this.deviceCategoryList = res.data.data.list;
				});
			},
			categoryIdListChange(e) {
				this.$set(this.companyArray, this.companyArray.categoryIdList, e);
			},
		},
	};
</script>

<style scoped></style>
